import './DashboardPage.css'
import {Tab, TabGroup, TabList, TabPanel, TabPanels} from "@tremor/react";
import {
    RemixiconComponentType,
    RiTeamLine,
    RiBarChart2Line,
    RiKanbanView,
    RiTaskLine, RiGroupLine
} from "@remixicon/react";
import {ReactNode, useEffect, useState} from "react";
import {Sidebar, Menu, MenuItem} from 'react-pro-sidebar';
import {FilterImage, ToggleSideBarLeftImage, ToggleSideBarRightImage} from "@components/filters/FilterImages";
import {DashboardFiltersView} from "@components/filters/DashboardFiltersView";
import {FilterId, FiltersProvider, useFiltersScope} from "@components/filters/FiltersContext";
import {
    ScopeForecastTabContent, SprintsTabContent,
    SummaryTabContent
} from './TabContent';
import {TeamsListTab} from "@tabs/teams/TeamsListTab";
import {TeamsCompareTab} from "@tabs/teams/TeamsCompareTab";
import {TeamVelocityTab} from "@tabs/teams/TeamVelocityTab";
import {TeamPerformanceTab} from "@tabs/teams/TeamPerformanceTab";
import {TeamQualityTab} from "@tabs/teams/TeamQualityTab";
import {TeamOpenIssuesTab} from "@tabs/teams/TeamOpenIssuesTab";
import {useParams} from "react-router-dom";
import {useTenantNavigate} from "@services/tenants";
import {Delayed} from "@components/common/Delayed";
import {SprintTab} from "@tabs/sprints/SprintTab";
import {SprintsListTab} from "@tabs/sprints/SprintsListTab";
import {MembersListTab} from "@tabs/members/MembersListTab";
import {MemberStatsTab} from "@tabs/members/MemberStatsTab";
import {ChatView} from "@components/chat/ChatView";

export default function DashboardPage() {
    return <FiltersProvider>
        <DashboardPageContent />
        <ChatView />
    </FiltersProvider>
}

function DashboardPageContent() {
    const {scopeFilters} = useFiltersScope()
    const [filtersBarOpen, setFiltersBarOpen] = useState(true);
    const [sidebarCollapsed] = useState(false);
    const {section: sectionName, tab: tabKey} = useParams()
    const navigate = useTenantNavigate()

    const onMenuItemClick = (section: Section) => {
        // if (sectionName === section.name)
        //     setCollapsed(!sidebarCollapsed);

        navigate(`dashboard/${section.name}`)
    }

    const filtersBarWidth = "16vw";
    const sidebarWidth = "11vw";
    const sidebarMinWidth = "140px";
    const sidebarCollapsedWidth = "50px";
    const showFilters = filtersBarOpen && scopeFilters.length > 0

    return <div
        style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "base-line",
            height: "100%",
            position: "relative",
        }}
    >
        <Sidebar collapsed={sidebarCollapsed} width={sidebarWidth} collapsedWidth={sidebarCollapsedWidth} style={{minWidth:sidebarMinWidth}}>
            <Menu
                menuItemStyles={{
                    button: {
                        [`&.ps-active`]: {
                            //borderBottom: "2px #3B82F6 solid",
                            color: "#3B82F6",
                        },
                        padding: "0px 8px"
                    },
                    icon: {
                        padding: 0,
                        marginRight: 4
                    }
                }}>
                {sections.map(x =>
                    <MenuItem key={x.name} onClick={() => onMenuItemClick(x)}
                              active={x.name === sectionName}
                              icon={<x.collapsedIcon style={{width: 20, height: 20, display: 'inline', paddingBottom: "2px"}} />}
                    >
                        {x.title}
                    </MenuItem>
                )}
            </Menu>
        </Sidebar>

        <main style={{
            height: "100%",
            width: "100%",
            minWidth: "400px",
            overflowY: "auto"
        }}>
            {sections.map(x =>
                x.name === sectionName && <TabedContent key={x.name} tabs={x.tabs}
                                                        index={getTabIndexOrDefault(x.tabs, tabKey)}
                                                        onIndexChange={i => navigate(`dashboard/${sectionName}/${x.tabs[i].key}`)} />
            )}
        </main>

        <div className="border-black/14 border-solid border-r-[1px] pt-[10px]">
            {showFilters && (
                <div className="mx-[10px] mb-[10px]" style={{minWidth: filtersBarWidth}} >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>Фильтры</span>
                        <button disabled={!scopeFilters.length} onClick={() => setFiltersBarOpen(false)}>
                            <span style={{fontSize: 18, lineHeight: 0}}><ToggleSideBarRightImage /></span>
                        </button>
                    </div>
                    <div className="mt-[10px]">
                        <DashboardFiltersView />
                    </div>
                </div>
            )}
            {!showFilters && (
                <div className="mx-[10px] mb-[10px]" style={{textAlign: "center"}}>
                    <button disabled={!scopeFilters.length} className="mt-1" onClick={() => setFiltersBarOpen(true)}>
                        <span className={"text-tremor-brand"}><ToggleSideBarLeftImage /></span>
                    </button>
                    <button disabled={!scopeFilters.length} className="mt-1" onClick={() => setFiltersBarOpen(true)}>
                        <FilterImage />
                    </button>
                </div>
            )}
        </div>
    </div>
}

type TabedContentProps = {
    tabs: ChartTabDef[]
    index: number
    onIndexChange?: (index: number) => void
}

function getTabIndexOrDefault(tabs: ChartTabDef[], key?: string) {
    if (!key) {
        return 0
    }
    const index = tabs.findIndex(x => x.key === key)
    return index >= 0 ? index : 0
}

function TabedContent({tabs, index, onIndexChange}: TabedContentProps) {
    const filtersScope = useFiltersScope()

    useEffect(() => {
        filtersScope.setScopeFilters(tabs[index].filters)
    }, [filtersScope, index, tabs]);

    return <div style={{padding: "0.5em 1em 1em 1em"}}>
        <TabGroup index={index} onIndexChange={onIndexChange}>
            <TabList>
                {tabs.map(x => <Tab key={x.key}>{x.title}</Tab>)}
            </TabList>
            <TabPanels>
                {tabs.map((x, i) => <TabPanel
                    key={x.key}
                    children={i === index
                        // For some reason at the moment when tab index changes, the tab panel client size is still 0,
                        // which is not allowed for echarts. Waiting so some time fixes the issue.
                        ? <Delayed>{x.component}</Delayed>
                        : undefined} />)}
            </TabPanels>
        </TabGroup>
    </div>
}

type ChartTabDef = {
    key: string
    title: string
    component: ReactNode,
    filters: FilterId[]
}

type Section = {
    name: string,
    title: string,
    tabs: ChartTabDef[],
    collapsedIcon: RemixiconComponentType
}

const sections: Section[] = [
    {
        name: "overview",
        title: "Сводка",
        collapsedIcon: RiBarChart2Line,
        tabs: [
            {
                key: 'summary',
                title: 'Сводка',
                component: <SummaryTabContent />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Teams,
                    FilterId.Projects,
                    FilterId.IssueTypes,
                    FilterId.DefectTypes,
                ]
            }
        ]
    },
    {
        name: "teams",
        title: "Команды",
        collapsedIcon: RiTeamLine,
        tabs: [
            {
                key: 'list',
                title: 'Список',
                component: <TeamsListTab />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.IssueTypes,
                ]
            },
            {
                key: 'compare',
                title: 'Сравнение',
                component: <TeamsCompareTab />,
                filters: [
                    FilterId.TimeRange,
                ]
            },
            {
                key: 'velocity',
                title: 'Скорость',
                component: <TeamVelocityTab />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Teams,
                    FilterId.IssueTypes,
                ]
            },
            {
                key: 'performance',
                title: 'Результативность',
                component: <TeamPerformanceTab />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Teams,
                    FilterId.IssueTypes,
                ]
            },
            {
                key: 'quality',
                title: 'Качество',
                component: <TeamQualityTab />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Teams,
                    FilterId.DefectTypes,
                ]
            },
            {
                key: 'open-issues',
                title: 'Текущие задачи',
                component: <TeamOpenIssuesTab />,
                filters: [
                    FilterId.Teams,
                    FilterId.IssueTypes,
                    FilterId.DefectTypes,
                ]
            },
        ]
    },
    {
        name: "members",
        title: "Участники",
        collapsedIcon: RiGroupLine,
        tabs: [
            {
                key: 'list',
                title: 'Список',
                component: <MembersListTab/>,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Teams,
                ]
            },
            {
                key: 'stats',
                title: 'Статистика участника',
                component: <MemberStatsTab />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Member,
                ]
            },
        ]
    },
    {
        name: "sprints",
        title: "Спринты",
        collapsedIcon: RiKanbanView,
        tabs: [
            {
                key: 'list',
                title: 'Список',
                component: <SprintsListTab />,
                filters: [
                    FilterId.SprintGroup,
                ]
            },
            {
                key: 'single',
                title: 'Сводка по спринту',
                component: <SprintTab />,
                filters: [
                    FilterId.Sprint,
                ]
            },
            {
                key: 'sprints-old',
                title: 'Результативность спринтов (old)',
                component: <SprintsTabContent />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Projects,
                    FilterId.Teams,
                    FilterId.IssueTypes,
                ]
            },
        ]
    },
    {
        name: "projects",
        title: "Проекты",
        collapsedIcon: RiTaskLine,
        tabs: [
            {
                key: 'scope-forecast',
                title: 'Прогнозирование завершения работ',
                component: <ScopeForecastTabContent />,
                filters: [
                ]
            },
        ]
    }
]