import './index.css'
import React from "react";

type ThreeDotLoaderProps = {
    className?: string
    style?: React.CSSProperties
}

export const ThreeDotLoader = ({className, style}: ThreeDotLoaderProps) => <div>
    <div className={`dot-pulse ml-4 mr-4 ${className}`} style={style}></div>
</div>