export class ApiRequestError extends Error {
    constructor(public statusCode: number, message: string = 'Request error', public errorCode?: string) {
        super(message);
        Object.setPrototypeOf(this, ApiRequestError.prototype);
    }

    isUnauthorized() {
        return this.statusCode === 401
    }

    isForbidden() {
        return this.statusCode === 403
    }

    isServerError() {
        return this.statusCode >= 500
    }
}

export class RedirectToAuthError extends ApiRequestError {
    constructor() {
        super(401, 'Redirecting to auth');
        Object.setPrototypeOf(this, RedirectToAuthError.prototype);
    }
}

export type UserDto = {
    name: string
    id: string
    isAuthorized: boolean,
    roles: string[]
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

type InternalSendOptions = {
    body?: any
    readResponseBody?: boolean
    queryParams?: URLSearchParams | string
    signal?: AbortSignal
    timeout?: number,
    refreshXsrfToken?: boolean
}

type VersionResponse = {
    version: string
}

export class Api {
    private getUrl(endpoint: string) {
        const tenant = window.location.pathname.split('/')[1]
        if (!tenant || tenant === '') {
            throw new Error('Tenant is not available for Api')
        }
        return `${window.location.origin}/${tenant}/api/${endpoint}`
    }

    private xsrf?: {requestToken: string, headerName: string}

    public getUser() {
        return this.get<UserDto>('user')
    }

    public async getVersion() {
        const response = await this.get<VersionResponse>('appInfo')
        return response.version
    }

    public get<TResponse>(endpoint: string, queryParams?: URLSearchParams | string, signal?: AbortSignal): Promise<TResponse> {
        return this.invoke('GET', endpoint, {queryParams, signal})
    }

    public async getXsrfTokens() {
        this.xsrf = await this.get<{requestToken: string, headerName: string}>('auth/xsrf');
    }

    public send(method: HttpMethod, endpoint: string, options: InternalSendOptions = {}): Promise<void> {
        return this.sendInternal<void>(method, endpoint, {...options, readResponseBody: false})
    }

    public invoke<TResponse>(method: HttpMethod, endpoint: string, options: InternalSendOptions = {}): Promise<TResponse> {
        return this.sendInternal<TResponse>(method, endpoint, options)
    }

    private async sendInternal<TResponse>(method: HttpMethod, endpoint: string, options: InternalSendOptions = {}): Promise<TResponse> {
        const headers:Record<string, string> = {}

        const { body, readResponseBody = true, queryParams, signal, timeout } = options;

        if (method !== 'GET' && this.xsrf) {
            headers[this.xsrf.headerName] = this.xsrf.requestToken
        }

        if (body) {
            headers['content-type'] = 'application/json;charset=UTF-8'
        }

        if (queryParams) {
            if (queryParams instanceof URLSearchParams) {
                if (endpoint.includes('?'))
                    endpoint += '&' + queryParams.toString()
                else
                    endpoint += '?' + queryParams.toString()
            } else {
                if (endpoint.includes('?'))
                    endpoint += '&' + queryParams.substring(1)
                else
                    endpoint += '?' + queryParams
            }
        }

        const response = await this.fetchWithTimeout(this.getUrl(endpoint), {
            timeout: timeout ?? 20000,
            method: method,
            body: body && JSON.stringify(body),
            headers,
            signal,
        })

        if (response.status === 401) {
            this.preventRedirectLoop();
            window.location.replace(this.getUrl(`auth/login?redirectUrl=${encodeURIComponent(window.location.href)}`))
            throw new RedirectToAuthError()
        }

        if (response.status >= 200 && response.status < 300) {
            if (readResponseBody) {
                if (response.headers.get('content-type')?.startsWith('application/json')) {
                    return await response.json() as TResponse
                }
                else {
                    throw new ApiRequestError(response.status, `Invalid content type: ${response.headers.get('content-type') || '<none>'}`)
                }
            } else {
                return undefined as TResponse
            }
        }

        if (response.status === 400 && response.headers.get('content-type')?.startsWith('application/json')) {
            const {message, code: errorCode} = await response.json() as {
                message?: string
                code?: string
            }

            if (errorCode === 'XsrfCheckFailed') {
                this.xsrf = undefined
                await this.getXsrfTokens()
                return await this.sendInternal<TResponse>(method, endpoint, options)
            }

            if (message) {
                throw new ApiRequestError(response.status, message, errorCode)
            }
        }

        throw new ApiRequestError(response.status)
    }

    private preventRedirectLoop() {
        const lastAuthRedirectAt = sessionStorage.getItem('lastAuthRedirectAt')
        const now = Date.now()
        if (lastAuthRedirectAt && now <= Date.parse(lastAuthRedirectAt) + 2000) {
            throw new Error('Authentication redirect loop detected')
        }
        sessionStorage.setItem('lastAuthRedirectAt', now.toString())
    }

    private async fetchWithTimeout(resource: string, options: RequestInit & {timeout?: number}) {
        const { timeout = 20000 } = options;

        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);

        const response = await fetch(resource, {
            ...options,
            signal: controller.signal
        });

        clearTimeout(id);

        return response;
    }
}

export const api = new Api()