export function ensureEndsWithFullDot(str: string) : string {
    return str.endsWith('?') || str.endsWith('!') || str.endsWith('.') ? str : str + '.';
}

declare global {
    interface String {
        ensureEndsWithFullDot(): string;
    }
}

String.prototype.ensureEndsWithFullDot = function() {
    if (!this) {
        return this
    }
    const str = this as string;
    return str.endsWith('?') || str.endsWith('!') || str.endsWith('.') ? str : str + '.';
}