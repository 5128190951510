import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {api, ApiRequestError} from "@services/api";

export default function TelegramAuthPage() {
    const [searchParams]  = useSearchParams();
    const [authorizing, setAuthorizing] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const chatId = searchParams.get('chatId');
    const authToken = searchParams.get('authToken');
    const username = searchParams.get('username');

    useEffect(() => {
        if (!chatId || !authToken) {
            return
        }

        setAuthorizing(true)
        api.send('POST', `telegram/auth`, {
            body: {
                chatId,
                authToken
            }
        }).catch(err => {
            console.error(err)
            setError((err instanceof ApiRequestError && err.message) || 'Server request error')
        }).finally(() => {
            setAuthorizing(false)
        })
    }, [chatId, authToken, setAuthorizing])

    if (!chatId || !authToken) {
        return <div>Ошибка: Неверный запрос авторизации.</div>
    }

    if (authorizing) {
        return <div>Авторизация чата...</div>
    }

    if (error) {
        return <div>Ошибка: {error.ensureEndsWithFullDot()}</div>
    }

    return <div>Авторизация телеграм чата {username ?? chatId} успешно завершена.</div>
}