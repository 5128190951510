import { api } from "./api";

export enum SenderType {
    User = "User",
    Assistant = "Assistant",
    SystemError = "SystemError",
    Debug = "Debug"
}

export type AiAssistantChatMessage = {
    id?: string;
    clientId?: string;
    message: string;
    sender: SenderType;
    timestamp: string; // This will be an ISO string from the Instant type in C#
}

export type AiAssistantChatSession = {
    messages: AiAssistantChatMessage[];
    isGeneratingResponse: boolean;
}

export type SendMessageRequest = {
    message: string;
    clientId: string;
}

export class AiChatApi {
    public async getSession() {
        return await api.get<AiAssistantChatSession>('aiChat');
    }

    public async sendMessage(message: string, clientId?: string) {
        return await api.invoke<AiAssistantChatMessage[]>('POST', 'aiChat/messages', {
            body: { message, clientId } as SendMessageRequest,
            timeout: 180000,
        });
    }

    public async cancelResponseGeneration() {
        return await api.send('POST', 'aiChat/cancelResponseGeneration');
    }

    public async startNewSession() {
        return await api.invoke<AiAssistantChatSession>('POST', 'aiChat/startNewSession');
    }
}

export const aiChatApi = new AiChatApi(); 