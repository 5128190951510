import React from "react";
import {useQuery} from "react-query";
import {api} from "@services/api";

export function AimgerVersion() {
    const {data: version} = useQuery('version', () => api.getVersion())

    return <div className={"absolute bottom-2 left-2 text-xs opacity-80"}>
        {version}
    </div>
}